import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CircleCheckBox from '../../common/CircleCheckBox'
import { formatPrice } from '../../utils/number'
import PopupSchedule from './PopupSchedule'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { FaArrowRight } from "react-icons/fa";



const DelivPickup = ({colorCode, delivOrPickup, setDelivOrPickup, unit = 'RON', isPickupAvail, isDelivAvail}) => {
    const { t } = useTranslation()
    const [showSchedule, setShowSchedule] = useState(false)
    const [pickupTime, setPickupTime] = useState({day: 'Today', time: 'Now'})
    const { setIsDelivPickup, setPickupData, setDelivPickupPrice } = useContext(ProductBusketContext)

    const handleChangepickup = (checked, price) => {
        if (!checked || !isPickupAvail) return
        setDelivOrPickup('pickup')
        setIsDelivPickup(true)
        setDelivPickupPrice(price)
      }
    const handleChangedelivery = (checked, price) => {
        if (!checked) return
        setDelivOrPickup('delivery')
        setIsDelivPickup(true)
        setDelivPickupPrice(price)
    }

    useEffect(() => {
        setPickupData(prev => ({
                ...prev,
                day: pickupTime.day,
                time: pickupTime.time
            }
        ))
    },[pickupTime])

    useEffect(() => {
        if (showSchedule) {
            document.body.classList.add('no-scroll')
          } else {
            document.body.classList.remove('no-scroll')
          }
    }, [showSchedule])
    
  return (
    <>
    
        <div className='px-[5px] border-b-2 pb-5 border-[#cbcac83c]'>
            <h3 className="text-[20px] mt-[15px]">
                {isDelivAvail && isPickupAvail ? t('product.deliv_pickup'): isDelivAvail && !isPickupAvail ? t('product.delivery') : t('product.pickup')}
            </h3>
            <div className='mt-2 space-y-3 pl-2'>
                <div onClick={() => handleChangepickup(true, 0)} className={`flex justify-between items-center w-full ${!isPickupAvail && 'text-[#858585]' }`}>
                    <div className="flex items-center gap-[10px]">
                        <CircleCheckBox
                        colorCode={colorCode}
                        checked={delivOrPickup === 'pickup'}
                        onChange={() => {}}
                        disabled={!isPickupAvail}
                        />
                        
                        <div>
                            <h4 className="font-normal text-[16px]">{t('product.pickup')}</h4>
                            <div className='text-sm'>20-25 min</div>
                        </div>
                    </div>
                    <div>{formatPrice(0)} {unit}</div>
                </div>
                {isDelivAvail && (
                    <div onClick={() => handleChangedelivery(true, 10)} className='flex justify-between items-center w-full'>
                        <div className="flex items-center gap-[10px]">
                            <CircleCheckBox
                            colorCode={colorCode}
                            checked={delivOrPickup === 'delivery'}
                            onChange={() => {}}
                            />
                            <div>
                                <h4 className="font-normal text-[16px]">{t('product.delivery')}</h4>
                                <div className='text-sm'>45-55 min</div>
                            </div>
                        </div>
                        <div>{formatPrice(0)} {unit}</div>
                    </div>
                )}
            </div>
        </div>
        {delivOrPickup === 'pickup' && (
            <div onClick={() => setShowSchedule(true)} className='px-[5px] flex items-center mt-3'>
                <img src='/assets/images/icons/schedule.svg' alt='schedule' width={24} height={24} />
                <div className='flex-1 ml-4'>
                    <div>{t('product.schedule_pickup')}</div>
                    {pickupTime && (
                        <div style={{color: colorCode}} className='text-sm'>{`${pickupTime?.day}, ${pickupTime.time}`}</div>

                    )}
                </div>
                <FaArrowRight />
            </div>
        )}
        {showSchedule && (
            <PopupSchedule colorCode={colorCode} handleClose={setShowSchedule} setPickupTime={setPickupTime} pickupTime={pickupTime} />
        )}
    </>
  )
}

export default DelivPickup