import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai'
import { BiLogoLinkedin } from 'react-icons/bi'
import CircleIcon from '../../common/CircleIcon'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import RectangleButton from '../../common/RectangleButton'
import { useTranslation } from 'react-i18next'
import useViewportWidth from '../../hooks/useViewportWidth'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ContactUs = ({ id }) => {
  const { t } = useTranslation()
  const [state, setState] = useState({})
  const [errors, setErrors] = useState({})
  const viewportWidth = useViewportWidth()
  let language = localStorage.getItem('i18nextLng')
  const emailPattern = /^[^ ]+@[^]+\.[a-z]{2,3}$/
  const phonePattern = /^\+?[0-9]{10,13}$/

  const validateForm = () => {
    const validationErrors = {}

    if (!state.from_name) validationErrors.from_name = 'Name is mandatory!'

    if (!state.role) validationErrors.role = 'Role is mandatory!'

    if (!state.phone) {
      validationErrors.phone = 'Phone Number is mandatory'
    } else if (!phonePattern.test(state.phone)) {
      validationErrors.phone = 'Invalid Phone Number'
    }

    if (!state.email) {
      validationErrors.email = 'Email Address is mandatory'
    } else if (!emailPattern.test(state.email)) {
      validationErrors.email = 'Invalid Email Address'
    }

    if (!state.message) validationErrors.message = 'Please enter a message'

    if (!state.condition)
      validationErrors.condition = 'You must accept the Terms&Conditions!'

    setErrors(validationErrors)

    return Object.keys(validationErrors).length === 0
  }

  const handleUpdate = (e) => {
    if (e.target.name === 'condition') {
      setState((prv) => ({
        ...prv,
        [e.target.name]: e.target.checked,
      }))
    } else {
      setState((prv) => ({
        ...prv,
        [e.target.name]: e.target.value,
      }))
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: undefined,
    }))
  }

  const handleSubmit = () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        general: 'You must complete all mandatory (*) fields!',
      }))
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/become-partner-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      })
        .then((res) => {
          toast.success('Successfully sent', {
            position: 'bottom-right',
          })
          setState({})
        })
        .catch((err) => {
          toast.error('Error occurred!', {
            position: 'bottom-right',
          })
        })
    }
  }

  return (
    <div id={id} className="relative overflow-x-clip">
      <img
        src="/assets/images/contact_img01.png"
        alt="contact img"
        className="landing-bg absolute my-auto lg:-right-[200px] md:-right-[200px] -right-[80px] md:h-[435px] h-[140px] md:top-[100%] lg:top-0 -top-[50px]"
      />
      <div className="container mt-[100px]">
        <div className="w-full text-black flex flex-col md:flex-row md:gap-[30px] lg:gap-[95px]">
          <h2
            className="text-[33px] font-bold leading-[40px] mb-[25px] md:hidden"
            style={{ whiteSpace: language === 'hu' ? 'inherit' : 'nowrap' }}
          >
            {t('homepage.contactus_title')}
          </h2>
          <div className="flex flex-col items-center md:items-start gap-[25px] md:gap-[60px] w-full md:w-[400px] md:order-1 order-2 mt-[50px] md:mt-0">
            <h2
              className="md:text-[55px] text-[40px] font-bold md:leading-[80px] leading-[49px] hidden md:block"
              style={{ whiteSpace: language === 'hu' ? 'inherit' : 'nowrap' }}
            >
              {t('homepage.contactus_title')}
            </h2>
            <div className="flex flex-col gap-[25px] items-center md:items-start">
              <div className="flex gap-[13px]">
                <LazyLoadImage
                  src="/assets/images/phone_icon.png"
                  className="w-[25px] h-[25px]"
                  alt=""
                  effect="opacity"
                />
                <p className="text-base not-italic font-normal leading-[28px]">
                  +40 747 911 321
                </p>
              </div>
              <div className="flex gap-[13px] items-center md:items-start">
                <LazyLoadImage
                  src="/assets/images/email_icon.png"
                  className="w-[25px] h-[25px]"
                  alt=""
                  effect="opacity"
                />
                <p className="text-base not-italic font-normal leading-[28px]">
                  support@adhocorder.ro
                </p>
              </div>
            </div>
            <div className="flex gap-[20px]">
              <a href="https://www.facebook.com/adhocfasteasy/">
                <CircleIcon Icon={AiFillFacebook} />
              </a>
              {/* <CircleIcon Icon={AiOutlineTwitter} /> */}
              <a href="https://www.instagram.com/adhocfasteasy/">
                <CircleIcon Icon={AiOutlineInstagram} />
              </a>
              <a href="https://www.linkedin.com/company/adhocfasteasy/">
                <CircleIcon Icon={BiLogoLinkedin} />
              </a>
            </div>
          </div>
          <div className="w-full max-w-[800px] flex flex-col items-center md:items-start justify-center md:order-2 order-1 md:flex-grow">
            <div className="flex flex-row gap-3 w-full h-[55px]">
              <div
                className="w-1/2 border-b text-start"
                style={{
                  borderColor: errors.from_name ? '#DC143C' : '#FFB800',
                }}
              >
                <input
                  placeholder="Nume *"
                  className="outline-none p-3"
                  name="from_name"
                  value={state.from_name}
                  onChange={handleUpdate}
                />
              </div>
              <div
                className="w-1/2 border-b text-start"
                style={{ borderColor: errors.role ? '#DC143C' : '#FFB800' }}
              >
                <input
                  placeholder="Rol *"
                  className="outline-none p-3"
                  name="role"
                  value={state.role}
                  onChange={handleUpdate}
                />
              </div>
            </div>
            <div className="flex flex-row gap-3 w-full h-[55px]">
              <div
                className="w-1/2 border-b text-start"
                style={{ borderColor: errors.email ? '#DC143C' : '#FFB800' }}
              >
                <input
                  placeholder="Email *"
                  className="outline-none p-3"
                  name="email"
                  value={state.email}
                  onChange={handleUpdate}
                />
              </div>
              <div
                className="w-1/2 border-b text-start"
                style={{ borderColor: errors.phone ? '#DC143C' : '#FFB800' }}
              >
                <input
                  placeholder="Telefon *"
                  className="outline-none p-3"
                  name="phone"
                  value={state.phone}
                  onChange={handleUpdate}
                />
              </div>
            </div>
            <div
              className="w-full border-b text-start"
              style={{ borderColor: errors.message ? '#DC143C' : '#FFB800' }}
            >
              <textarea
                className="p-3 w-full min-h-[126px] outline-none bg-transparent"
                placeholder="Mesajul tău ... *"
                name="message"
                value={state.message}
                onChange={handleUpdate}
              />
            </div>
            {errors.message && (
              <p className="text-red-500 font-bold mt-1">{errors.message}</p>
            )}
            <div
              className={`${
                viewportWidth < 1400 && viewportWidth > 1022
                  ? 'lg:w-[85%] xl:w-[90%]'
                  : 'w-full'
              } p-3 text-start flex gap-3 mt-[10px]`}
            >
              <input
                type="checkbox"
                name="condition"
                checked={state.condition}
                onChange={handleUpdate}
              />
              <p className="text-base not-italic font-normal">
                {t('homepage.contactus_agree')}{' '}
                <a
                  target="_blank"
                  href="/terms-and-conditions"
                  className="text-[#487ADA] underline cursor-pointer"
                >
                  {t('footer.terms')}
                </a>{' '}
                precum și{' '}
                <a
                  target="_blank"
                  href="/privacy-policy"
                  className="text-[#487ADA] underline cursor-pointer"
                >
                  {t('footer.privacy')}
                </a>
              </p>
            </div>
            {errors.condition && (
              <p className="text-red-500 font-bold">{errors.condition}</p>
            )}
            <div className="mt-[15px] flex justify-center items-center flex-col">
              <RectangleButton
                className="py-[15px] px-[63px]"
                text={t('register.submit')}
                onClick={handleSubmit}
              ></RectangleButton>
              {errors.general && (
                <p className="text-red-600 font-bold uppercase mt-4">
                  {errors.general}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
